import { Button, Row, Col } from '@commonsku/styles';
import React, { useEffect, useRef, useState } from 'react';
import CurvyBackgroundSVG from './CurvyBackgroundSVG';
import { window } from '../global';
import { useIdentity } from '../hooks';
import { getIdentityUtils } from '../utils';

const CommonskuMainLogoBlue = () => {
  return <img
    src="/images/commonsku-main-logo-blue.svg" alt="commonsku" style={{ height: '43.75px', marginLeft: '20px' }}
  />;
};

const AllowDeny = ({scopes, client_name}) => {
  const identity = useIdentity();
  const { hasCapabilities } = getIdentityUtils(identity);
  const [allow, setAllow] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (allow) {
      formRef.current.submit();
    }
  }, [allow]);

  return <>
    <Col style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
      height: '100%', width: '100%', overflow: 'hidden'
    }}>
      <Row style={{ flex: '0 0 85px', margin: 0, width: '100%', backgroundColor: 'white' }}>
        <a href="https://www.commonsku.com/" style={{ padding: '20px 0' }}><CommonskuMainLogoBlue/></a>
        <hr style={{ margin: 0, width: '100%', maxWidth: '100rem' }} />
      </Row>
      <Col style={{
        flex: '1 1 0', flexDirection: 'column', justifyContent: 'center',
        overflow: 'auto', padding: '40px 20px 20px', textAlign: 'center'
      }}>
        <Row style={{ marginBottom: '32px', justifyContent: 'center' }}>
          {
            client_name.toLowerCase() === 'zapier'
            ? <img src="/images/zapier-logo_black.svg" alt="zapier" style={{ marginRight: '20px' }} />
            : <h1 style={{ marginRight: '20px', marginTop: '-10px' }}>{client_name}</h1>
          }
          <img src="/images/sync_alt.svg" alt="sync" />
          <CommonskuMainLogoBlue/>
        </Row>
        {
          client_name.toLowerCase() === 'zapier' && !hasCapabilities(['CONNECT-ZAPIER'])
          ? <div style={{ fontSize: '32px', fontStyle: 'normal', fontWeight: 600, marginBottom: '48px' }}>
            You do not have permission to access {client_name}
            <br /> please contact an administrator.
          </div>
          : <>
            <div style={{ fontSize: '32px', fontStyle: 'normal', fontWeight: 600, marginBottom: '48px' }}>
              {client_name} is requesting permission
              <br /> to access your commonsku account
            </div>
            {scopes.length === 0 ? null : <div style={{ justifyContent: 'center', display: 'inline-grid' }}>
              <b style={{ textAlign: 'left' }}>{client_name} will be able to: </b>
              <ul style={{ listStyleType: 'none', textAlign: 'left', marginTop: '16px' }}>
                {scopes.map((scope, index) => (
                  <li key={index} style={{ marginBottom: '10px' }}> &rarr; {scope.description}</li>
                ))}
              </ul>
            </div>}
            <form ref={formRef} action={""} method="POST">
              <Row style={{ justifyContent: 'center' }}>
                <input name="allow" value={allow ? "1" : "0"} type="hidden" />
                <Button variant="secondary" size="medium" id="deny" style={{ marginRight: '24px' }} onClick={(e) => {
                  e.preventDefault();
                  window.close();
                }}>Deny</Button>
                <Button variant="cta" size="medium" id="allow" onClick={(e) => {
                  e.preventDefault();
                  setAllow(true);
                }}>Allow</Button>
              </Row>
            </form>
            <div style={{ marginTop: '20px' }}>
              <a>Learn</a> more about this integration.
            </div>
          </>
        }
      </Col>
    </Col>
    <div style={{ position: 'fixed', bottom: 0, margin: 0, padding: 0, lineHeight: 0, width: '100%', zIndex: '-1' }}>
      <CurvyBackgroundSVG height={150}/>
    </div>
  </>;
};

export default AllowDeny;
